export default function IconCalendar() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="-0.75 -0.75 16 16"
      id="Calendar-Mark--Streamline-Micro"
      height="100%"
      width="100%"
    >
      <desc>{'Calendar Mark Streamline Icon: https://streamlinehq.com'}</desc>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.6250000000000004 0.7250000000000001v1.4500000000000002"
        strokeWidth={1.5}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.875000000000002 0.7250000000000001v1.4500000000000002"
        strokeWidth={1.5}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.350150000000003 13.350150000000003A1.4500000000000002 1.4500000000000002 0 0 1 12.325000000000001 13.775000000000002h-10.150000000000002a1.4500000000000002 1.4500000000000002 0 0 1 -1.4500000000000002 -1.4500000000000002v-8.700000000000001a1.4500000000000002 1.4500000000000002 0 0 1 1.4500000000000002 -1.4500000000000002h10.150000000000002a1.4500000000000002 1.4500000000000002 0 0 1 1.4500000000000002 1.4500000000000002v8.700000000000001a1.4500000000000002 1.4500000000000002 0 0 1 -0.42485 1.02515Z"
        strokeWidth={1.5}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.8062500000000004 6.6613000000000016a0.36250000000000004 0.36250000000000004 0 0 1 0 -0.7250000000000001"
        strokeWidth={1.5}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.8062500000000004 6.6613000000000016a0.36250000000000004 0.36250000000000004 0 0 0 0 -0.7250000000000001"
        strokeWidth={1.5}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.8062500000000004 10.0137a0.36250000000000004 0.36250000000000004 0 0 1 0 -0.7250000000000001"
        strokeWidth={1.5}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.8062500000000004 10.0137a0.36250000000000004 0.36250000000000004 0 0 0 0 -0.7250000000000001"
        strokeWidth={1.5}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.250000000000001 6.6613000000000016a0.36250000000000004 0.36250000000000004 0 0 1 0 -0.7250000000000001"
        strokeWidth={1.5}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.250000000000001 6.6613000000000016a0.36250000000000004 0.36250000000000004 0 0 0 0 -0.7250000000000001"
        strokeWidth={1.5}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.250000000000001 10.0137a0.36250000000000004 0.36250000000000004 0 0 1 0 -0.7250000000000001"
        strokeWidth={1.5}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.250000000000001 10.0137a0.36250000000000004 0.36250000000000004 0 0 0 0 -0.7250000000000001"
        strokeWidth={1.5}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.693750000000001 6.6613000000000016a0.36250000000000004 0.36250000000000004 0 0 1 0 -0.7250000000000001"
        strokeWidth={1.5}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.693750000000001 6.6613000000000016a0.36250000000000004 0.36250000000000004 0 0 0 0 -0.7250000000000001"
        strokeWidth={1.5}
      />
    </svg>
  );
}
